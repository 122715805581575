/* fonts.css */
@font-face {
    font-family: 'Source Sans Pro';
    src: url('./fonts/source-sans-pro/SourceSans3-Regular.otf.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Source Sans Pro';
    src: url('./fonts/source-sans-pro/SourceSans3-Bold.otf.woff') format('woff');
    font-weight: bold;
    font-style: normal;
  }
  
  /* Fügen Sie weitere @font-face Regeln für die anderen Schriftschnitte hinzu */
  